// Generated by Framer (4431e6b)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Typeform from "https://framerusercontent.com/modules/F6IzcnH090BibK8JiJh0/MfEMY4LeLoFqPUtJ3OJA/Typeform.js";
const TypeformFonts = getFonts(Typeform);

const cycleOrder = ["QDfYiA67A"];

const variantClassNames = {QDfYiA67A: "framer-v-1i8w8nl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "QDfYiA67A", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QDfYiA67A", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-J3eTM", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1i8w8nl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QDfYiA67A"} ref={ref} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><motion.div className={"framer-kg88eq-container"} layoutDependency={layoutDependency} layoutId={"PwoCuzup3-container"} transformTemplate={transformTemplate}><Typeform autoFocus formId={"uzOmeufz"} height={"100%"} hideFooter={false} hideHeaders={false} id={"PwoCuzup3"} layoutId={"PwoCuzup3"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-J3eTM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-J3eTM .framer-1j7qufh { display: block; }", ".framer-J3eTM .framer-1i8w8nl { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 834px; will-change: transform; }", ".framer-J3eTM .framer-kg88eq-container { flex: none; height: 90%; position: relative; width: 95%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-J3eTM .framer-1i8w8nl { gap: 0px; } .framer-J3eTM .framer-1i8w8nl > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-J3eTM .framer-1i8w8nl > :first-child { margin-top: 0px; } .framer-J3eTM .framer-1i8w8nl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 834
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerRqRBfVdSr: React.ComponentType<Props> = withCSS(Component, css, "framer-J3eTM") as typeof Component;
export default FramerRqRBfVdSr;

FramerRqRBfVdSr.displayName = "Typeform Modal Content";

FramerRqRBfVdSr.defaultProps = {height: 800, width: 834};

addFonts(FramerRqRBfVdSr, [...TypeformFonts])